import $ from 'jquery'
import { resetDropzone } from './form'
import { masksPackage } from './masks'


// Fonction contenant tous les messages
function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}


// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        return // eslint-disable-next-line semi

      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if (nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {
      let type = element[0].getAttribute('type')
      if (type == 'radio' || type == 'checkbox')
        error.appendTo(element.parent().parent().parent().find('.js-form-error'))
      else
        error.appendTo(element.parent().parent().find('.js-form-error'))

      // Ajouter la class d'erreur au champs
      var errorField = element.closest('.form__grid-item').children()
      var className = errorField.attr('class').split(' ')[0]
      errorField.addClass(className+'--error')
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).on('click', function () {
    $(form).submit()
  })
}


// Reset de validation
export function formSuccess(form, data) {
  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading')
    $('.stripe-loading-indicator').addClass('loaded')
    $(form)[0].reset()
    resetDropzone(form)
    if (typeof grecaptcha !== 'undefined') grecaptcha.reset()

    //<div class="alert alert-success">x</div>

    var message = 'Le formulaire est bien envoyé !'
    var confirmationContainer = form.querySelector('.js-confirmation-message')

    if (confirmationContainer.innerHTML != '') {
      message = confirmationContainer.innerHTML
    } else {
      if (document.getElementsByTagName('html')[0].getAttribute('lang') == 'en') {
        message = 'The form has been sent !'
      }
    }

    // Créer l'élément
    var confirmationNode = document.createElement('div')
    confirmationNode.className = 'alert alert-success'
    confirmationNode.textContent = message

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode)

    // Cacher le formulaire
    form.style.display = 'none'

    //$.oc.flashMsg({text: message ,class: 'success'})
  }
}


// La validation des courriels
function validationEmail() {
  $.validator.addMethod('courriel', function (value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}


// La validation du formulaire d'exemple
export function formExample() {
  validationEmail()

  var m = getMessages()

  var rules = {
    dropdownExample: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    message: { required: true },
    cv: { required: true },
    radioExampleInline: { required: true },
    checkbox: { required: true },
  }
  var messages = {
    dropdownExample: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email },
    phone: { minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    radioExampleInline: { required: m.required },
    checkbox: { required: m.required },
  }

  formValidation('#form-example', '#form-example-submit', rules, messages)
}


// La validation du formulaire contact
export function formContact(){
  validationEmail()

  var m = getMessages()

  var rules = {
    firstname:          { required: true },
    lastname:           { required: true },
    email:              { required: true, email: true, courriel: true },
    phone:              { required: true, minlength: 12 },
    message:            { required: true },
  }
  var messages = {
    firstname:          { required: m.required },
    lastname:           { required: m.required },
    email:              { required: m.required, email: m.email, courriel: m.email },
    phone:              { required: m.required, minlength: m.phone },
    message:            { required: m.required },
  }

  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}

// La validation du formulaire d'emplois
export function formJobs() {
  validationEmail()
  masksPackage()

  var m = getMessages()

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')

  var rules = {
    firstname:    { required: true },
    lastname:     { required: true },
    email:        { required: true, courriel: true },
    phone:        { minlength: 12 },
    cv:           { accept: 'pdf', filesize: 5242880, required: true },
    motivation:   { accept: 'pdf', filesize: 5242880 },
  }
  var messages = {
    firstname:    { required: m.required },
    lastname:     { required: m.required },
    email:        { required: m.required, courriel: m.email },
    phone:        { minlength: m.phone },
    cv:           { accept: m.accept, required: m.required },
    motivation:   { accept: m.accept },
  }

  formValidation('#form-jobs', '#form-jobs-submit', rules, messages)
}