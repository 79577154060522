// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// import Swiper from 'swiper/bundle'

// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import Swiper from 'swiper/bundle'
import anime from 'animejs/lib/anime.es.js'
import { Accordions } from '../classes/Accordions'
import { OBSERVER } from '../main'

// Création du slider des bannières
export function homeBanner() {

  // Slider des images
  let homeBannerSlider = new Swiper(".js-home-banner-slides", {
    slidesPerView: 1,
    loop: true,
    loopedSlides: 3,
    autoplay: {
    delay: 5000,
    },
  })
}


// Création du slider de nouvelles
export function alertsBanner() {
  if (document.querySelectorAll('.js-alerts-slider').length != 0) {

    // Slider des images
    let alertsBanner = new Swiper('.js-alerts-slider', {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.js-alerts-banner-next',
        prevEl: '.js-alerts-banner-prev',
      },
      on: {
        transitionStart: function (e) {
          let dataset = e.el.querySelector('.swiper-slide-active').dataset.alertColor
          switch(dataset) {
          case 'vert':
            document.documentElement.style.setProperty('--alerts-background-color', '#d4edda')
            document.documentElement.style.setProperty('--alerts-color', '#155724')
            break
          case 'jaune':
            document.documentElement.style.setProperty('--alerts-background-color', '#fff3cd')
            document.documentElement.style.setProperty('--alerts-color', '#856404')
            break
          case 'orange':
            document.documentElement.style.setProperty('--alerts-background-color', '#ffe6cd')
            document.documentElement.style.setProperty('--alerts-color', '#a75d14')
            break
          default:
            document.documentElement.style.setProperty('--alerts-background-color', '#f8d7da')
            document.documentElement.style.setProperty('--alerts-color', '#721c24')
          }
        },
      },
    })

    alertsBanner.on('slideChange', (e) => {
      var counter = document.querySelector('.js-alerts-current')
      counter.innerHTML = e.realIndex +1
    })

    function closeAlerts(){
      anime({
        targets: '.js-alerts-banner',
        height: '0px',
        easing: 'easeInQuart',
        duration: 400,
      })
      document.documentElement.style.setProperty('--alerts-height', '0px')
    }
    OBSERVER.add({
      name: 'closeAlerts',
      event: 'click',
      target: '.js-alerts-banner-close',
      function: closeAlerts
    })
    OBSERVER.on('closeAlerts')
    
  }
  else {
    document.documentElement.style.setProperty('--alerts-height', '0px')
  }
}


// Création du slider des logos
export const swiperLogos = () => {

  // Slider des logos
  let logosSlider = new Swiper('.js-logos-slides', {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: false,
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      440: {
        slidesPerView: 4,
      },
      1100: {
        slidesPerView: 5,
      },
      1300: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },
  })
}