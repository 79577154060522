
import $ from 'jquery'
import Overlay from '../vendor/overlay.js'
import { Accordions } from '../classes/accordions'
import { OBSERVER } from './../main.js'
import { copyTextToClipboard, initCalendar } from './functions.js'

// Fonction gérant l'overlay popup
export function overlayPopup(root = document) {
  if(document.querySelector('[data-overlay="popup"]') !== null){
    let popup = new Overlay({
      name: 'popup',
      timeout: {
        delay: 300,
      },
      click: {
        buttons: {
          close: '.js-close-overlay-popup'
        }
      },
      options: {
        speed: 800,
        root: root
      }
    })

    popup.init()
  }
}

// Fonction gérant l'overlay de partage
export function overlayShare() {
  let siteName = window.config.site_name

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  $('.js-copy-share').on('click', function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $('.js-share').on('click', function(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}

// Fonction gérant l'overlay Calendrier
export function overlayCalendar() {

    let calendar = new Overlay({
      name: 'calendar',
      create: { close: false },
      click: { 
        buttons: {
          toggle: '.js-toggle-overlay-calendar',
          switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks',
          close: '.js-close-overlay-calendar, .calendar .day:not(.disabled)'
        }
      },
      options: {
        speed: 800,
        root: document
      }
    })

    //initCalendar()
    calendar.init()
}

// Fonction gérant l'overlay Menu
export function overlayMenu() {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: {
      close: false
    },
    click: {
      buttons: {
        toggle: '.js-toggle-overlay-menu',
        close: 'a[href]',
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  })

  menu.init()

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  })

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
  })

  const onClose = () => {
    mainLevel.closeAll()
    secondLevel.closeAll()
  }

  const onCloseMainLevel = () => {
    secondLevel.closeAll()
  }

  OBSERVER.add({ name: 'overlayMenu', event: 'onCloseOverlayMenu', function: onClose})
  OBSERVER.add({ name: 'overlayMenu', event: 'onCloseAccordionsMainLevel', function: onCloseMainLevel})
  OBSERVER.add({ name: 'overlayMenu', event: 'onCloseAllAccordionsMainLevel', function: onCloseMainLevel})
  OBSERVER.on('overlayMenu')
}

// Fonction gérant l'overlay Dropdown
export function overlayDropdown() {
  let dropdown = new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        trigger: '.js-trigger-overlay-dropdown',
        close: 'a[href], .js-close-overlay-dropdown',
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: true,
    }
  })

  dropdown.init()
}