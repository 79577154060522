import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, calendar, ogContent, changeSelect, lexiconFilters, adresseSearchResultsLoaded } from './functions/functions.js'
import { alertsBanner, homeBanner, swiperLogos } from './functions/sliders.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, tailSelect } from './functions/form.js'
import { overlayPopup, overlayShare, overlayCalendar, overlayMenu, overlayDropdown } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formExample, formSuccess, formContact, formJobs } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { removeHoverOnMobile, hundredVH } from './functions/helper.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.adresseSearchResultsLoaded = adresseSearchResultsLoaded

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    hundredVH()
    alertsBanner()
    overlayDropdown()
    overlayMenu()
    overlayShare()
    overlayPopup()
    backendKeyBind()
    SCROLLFIRE.init()
    removeHoverOnMobile()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formExample()
          calendar()
          changeSelect('#catFilterSelect')
          swiperLogos()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          homeBanner()
          swiperLogos()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsView',
        beforeEnter() {
          tailSelect()
          changeSelect('#catFilterSelect')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsDetail',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          tailSelect()
          changeSelect('#catFilterSelect')
          formJobs()
          formsPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsDetail',
        beforeEnter() {
          formJobs()
          formsPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventsView',
        beforeEnter() {
          calendar()
          tailSelect()
          changeSelect('#catFilterSelect')
          window['lazyload'] = new LazyLoad()
          overlayCalendar()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          tailSelect()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'faqsView',
        beforeEnter() {
          tailSelect()
          new Accordions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'lexiqueView',
        beforeEnter() {
          lexiconFilters()
          new Accordions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'galleryView',
        beforeEnter() {
          tailSelect()
          changeSelect('#catFilterSelect')
          new Accordions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formContact()
          masksPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      }
    ]
  })
}


